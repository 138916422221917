
import { Options, Vue } from "vue-class-component";
import CoordinatorService from "@/services/coordinator-service";
import CourseService from "@/services/course-service";
import LocalStorageService from "@/services/localstorage-service";
import {
  CourseOccurrence,
  NoteTakerProposedCourse,
  Person,
  Student,
  NoteTakerOffer,
} from "@/interfaces/api";
import { Constants } from "@/constants/constants";
import DetailsRow from "@/components/DetailsRow.vue";
import Table from "@/components/Table.vue";
import DetailsHeader from "@/components/DetailsHeader.vue";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import EditButtonsGroup from "@/components/Form/EditButtonsGroup.vue";
import SelectItem from "@/components/Form/SelectItem.vue";
import LuSpinner from "@/components/Lu/LuSpinner.vue";
import MappingHelper from "@/helpers/mapping-helper";
import DateHelper from "@/helpers/date-helper";
import { INameAndId } from "@/interfaces/INameAndId";

@Options({
  components: {
    DetailsRow,
    Table,
    DetailsHeader,
    ConfirmationModal,
    SelectItem,
    LuSpinner,
    EditButtonsGroup,
  },
  data: () => ({
    /*
     * Holds the id of the occurrence and is populated from the route's id parameter when the page loads
     */
    currentOccurrenceId: 0,
    /*
     * Holds the occurrence data and is populated by a call to the occurrence service when the page loads
     */
    occurrence: null,
    /*
     * Holds a list of coordinator names and ids and is populated by a call to the coordinator service when the page loads
     */
    coordinators: new Array<INameAndId>(),
    /**
     * Used to show or hide spinner
     */
    isLoading: false,
    /**
     * If edit-mode is enabled or not
     */
    editEnable: false,
    /**
     * The id and name of the currently saved coordinator
     * Used in order to revert to in the case that update fails or more than one save is performed
     */
    currentCoordinator: { id: 0, name: "" },
    /**
     * The course has been removed from Ladok and contains property values "unavailable"
     */
    isRemovedFromLadok: false,
  }),
  computed: {
    /*
     * Used by the previous/next button logic
     */
    allCourseOccurrenceIds() {
      const sortedCourseOccurrenceIds = LocalStorageService.getItem<number[]>(
        Constants.SortedCourseOccurrenceIds
      );
      return sortedCourseOccurrenceIds;
    },
    /*
     * The id of the occurrence to load when the next button is clicked
     */
    nextCourseOccurrenceId() {
      const currentIdIndex = this.allCourseOccurrenceIds.indexOf(
        this.currentOccurrenceId
      );
      let nextIdIndex = currentIdIndex + 1;
      if (nextIdIndex >= this.allCourseOccurrenceIds.length) {
        nextIdIndex = 0;
      }
      return this.allCourseOccurrenceIds.slice(nextIdIndex, nextIdIndex + 1)[0];
    },
    /*
     * The id of the occurrence to load when the previous button is clicked
     */
    previousCourseOccurrenceId() {
      const currentIdIndex = this.allCourseOccurrenceIds.indexOf(
        this.currentOccurrenceId
      );
      let previousIdIndex = currentIdIndex - 1;
      if (previousIdIndex < 0) {
        previousIdIndex = this.allCourseOccurrenceIds.length - 1;
      }
      return this.allCourseOccurrenceIds.slice(
        previousIdIndex,
        previousIdIndex + 1
      )[0];
    },
    /**
     * Holds course title value depending on chosen locale
     */
    courseTitle() {
      return this.$i18n.locale === "sv"
        ? this.occurrence.titleSv
        : this.occurrence.titleEn;
    },
    /**
     * Holds faculty value depending on chosen locale
     */
    faculty() {
      return this.$i18n.locale === "sv"
        ? this.occurrence.facultySv
        : this.occurrence.facultyEn;
    },
    /**
     * Holds institution value depending on chosen locale
     */
    institution() {
      return this.$i18n.locale === "sv"
        ? this.occurrence.institutionSv
        : this.occurrence.institutionEn;
    },
    /**
     * Holds the active student to show in the table
     */
    studentRows() {
      return this.occurrence.students.map((student: Student) => ({
        id: student.id,
        name: `${student.firstName} ${student.lastName}`,
        personalId: student.personalId,
      }));
    },
    /**
     * Holds the active notetakers to show in the table
     */
    noteTakerRows() {
      const occurrence = this.occurrence as CourseOccurrence;
      const noteTakers = occurrence.noteTakers;
      if (!noteTakers) {
        return [];
      }

      const noteTakerRows = noteTakers?.map((noteTaker) => ({
        id: noteTaker.id,
        name: `${noteTaker.firstName} ${noteTaker.lastName}`,
        personalId: noteTaker.personalId,
        started: noteTaker.supportCourseOccurrences?.find(
          (course) => course.courseOccurrenceId === occurrence.id
        )?.assignmentStart,
      }));

      return noteTakerRows;
    },
    /**
     * Only notetakers that have an intrest but not an assignment
     */
    proposedNoteTakersWithoutAssignment(): NoteTakerProposedCourse[] {
      const occurrence = this.occurrence as CourseOccurrence;
      const proposedNoteTakers = occurrence.proposedNoteTakers;
      if (!proposedNoteTakers) {
        return [];
      }
      return proposedNoteTakers?.filter(
        ({ noteTaker: proposedNoteTaker }) =>
          !occurrence.noteTakers?.find(
            (noteTaker) => noteTaker.id === proposedNoteTaker.id
          )
      );
    },
    /**
     * Only notetakers that have an intrest but not an assignment
     */
    proposedNoteTakersWithoutAssignmentRows() {
      const occurrence = this.occurrence as CourseOccurrence;

      const proposedNoteTakersWithoutAssignment: NoteTakerProposedCourse[] =
        this.proposedNoteTakersWithoutAssignment;

      return proposedNoteTakersWithoutAssignment.map(
        (noteTakerProposedCourse) => {
          const noteTaker = noteTakerProposedCourse.noteTaker;
          const workOffers = occurrence.noteTakerOffers?.filter(
            (offer) => offer.noteTakerId === noteTaker.id
          );

          const lastWorkOffer = workOffers
            ?.sort((offerA: NoteTakerOffer, offerB: NoteTakerOffer) =>
              DateHelper.dateCompareFunction(offerA.sent, offerB.sent)
            )
            .reverse()[0];

          return {
            id: noteTaker.id,
            name: `${noteTaker.firstName} ${noteTaker.lastName}`,
            personalId: noteTaker.personalId,
            interest: noteTakerProposedCourse.inserted,
            offerSent: lastWorkOffer?.sent,
            offerCanceled: lastWorkOffer?.canceled,
            offerId: lastWorkOffer?.id,
          };
        }
      );
    },
    /**
     * Holds the summary of note-taker uploads to show in the table
     */
    noteTakerUploads() {
      const occurrence = this.occurrence as CourseOccurrence;
      const uploads = occurrence.noteTakerUploadSummaries;
      if (!uploads) {
        return [];
      }

      return uploads;
    },
    /*
     * Prevents using the save button unless the coordinator has been changed
     */
    saveBtnDisabled(): boolean {
      return this.occurrence.coordinatorId === this.currentCoordinator.id;
    },
    /**
     * The unavailable reason translation for the current locale
     */
    notAvailableReason(): string {
      return this.$t("course-occurrence.unavailable-reason", {
        courseUid: this.occurrence.courseUid,
        occurrence: this.occurrence.occurrence,
      });
    },
    /*
     * Prevents change of student or note-taker after course end
     */
    studentOrNoteTakerBtnDisabled(): boolean {
      return DateHelper.dateAisBeforeDateB(
        this.occurrence.courseEndDate,
        new Date()
      );
    },
  },
  methods: {
    /*
     * Formats the date to YYYY-MM-DD
     */
    formatDate(date: Date): string {
      return DateHelper.formatDate(date);
    },
    /**
     * Fetches the course occurrence with the id from route parameter
     */
    getCourseOccurrence(): void {
      this.isLoading = true;
      const localstorageLang = LocalStorageService.getItem<string>("language");
      this.$root.$i18n.locale = localstorageLang;
      if (
        this.$route.name === "CourseOccurrenceDetails" &&
        this.$route.params.id
      ) {
        this.currentOccurrenceId = parseInt(this.$route.params.id);
        CourseService.getCourseOccurrenceById(this.currentOccurrenceId)
          .then((data: CourseOccurrence | undefined) => {
            this.occurrence = data;
            this.isRemovedFromLadok =
              this.occurrence.titleSv === Constants.NotAvailable;
            this.updateCurrentCoordinator();
            this.getCoordinators();
            this.isLoading = false;
          })
          .catch((e: Error) => {
            console.error(e);
          });
      }
    },
    /**
     * Fetches the coordinators for the function with the supplied id
     */
    getCoordinators() {
      CoordinatorService.getCachedCoordinatorsByFunctionId(
        Constants.NoteTakingFunctionId
      ).then((coordinators) => {
        this.coordinators = coordinators;
      });
    },
    /*
     * Updates the course occurrence - triggered from EditButtonsGroup component
     */
    updateCourseOccurrence(): void {
      this.isLoading = true;
      CourseService.updateCourseOccurrence(this.occurrence).then(
        (success: boolean) => {
          if (success) {
            this.$refs["editButtonsGroup"].showSuccessStatus(true);
            this.updateCurrentCoordinator();
          } else {
            this.$refs["editButtonsGroup"].showSuccessStatus(false);
            this.occurrence.coordinatorId = this.currentCoordinator.id;
            this.occurrence.coordinatorName = this.currentCoordinator.name;
          }
          this.isLoading = false;
          this.editEnable = false;
        }
      );
    },
    /*
     * Updates the current coordinator
     */
    updateCurrentCoordinator(): void {
      this.currentCoordinator = {
        id: this.occurrence.coordinatorId,
        name: this.occurrence.coordinatorName,
      };
    },
    /*
     * Navigates to the course occurrence with the specified id
     * Called from the next/previous button click
     */
    navigateToCourseOccurrence(id: number): void {
      this.$router.push({
        name: "CourseOccurrenceDetails",
        params: { id: id },
      });
    },
    /*
     * Navigates to the course occurrence overview
     * Called from the overview button click
     */
    navigateToOverview(): void {
      this.$router.push({
        name: "CourseOccurrenceList",
      });
    },
    /**
     * Returns a color based on course occurrence status
     *
     * @param {string} status The status we want a color for
     */
    statusBgColor(status: string): string {
      return CourseService.courseOccurrenceStatusColor(status);
    },
    /**
     * Returns a color based on status
     *
     * @param {string} status The status we want a color for
     */
    statusTextColor(status: string): string {
      return status === "Yellow" ? "black" : "white";
    },
    /**
     * Returns a text based on status
     *
     * @param {string} status The status we want a tooltip text for
     */
    statusText(status: string): string {
      return this.$t(CourseService.courseOccurrenceStatusText(status));
    },
    /**
     * Returns a list of links to Details view for the specified person list
     */
    detailViewLinks(
      personList: Array<Person>,
      detailsViewRoute: string
    ): Array<string> {
      const localstorageLang = LocalStorageService.getItem<string>("language");
      this.$root.$i18n.locale = localstorageLang;
      const links = MappingHelper.mapPersonToDetailViewLinks(
        personList,
        `/administrator/${detailsViewRoute}`
      );

      return links;
    },
    /**
     * Delete the specified student from the course occurrence
     */
    deleteStudent(studentId: number): Promise<boolean> {
      return CourseService.deleteStudentFromCourseOccurrence(
        this.currentOccurrenceId,
        studentId
      ).then(this.getCourseOccurrence);
    },
    /**
     * Delete the specified note taker from the course occurrence
     */
    deleteNoteTaker(noteTakerId: number): Promise<boolean> {
      this.$store.commit("showSpinner", true);
      return CourseService.deleteNoteTakerFromCourseOccurrence(
        this.currentOccurrenceId,
        noteTakerId
      )
        .then(this.getCourseOccurrence)
        .finally(() => {
          this.$store.commit("showSpinner", false);
        });
    },
    sendOffer(noteTakerId: number): Promise<boolean> {
      return CourseService.sendOfferToNoteTaker(
        this.currentOccurrenceId,
        noteTakerId
      ).then(this.getCourseOccurrence);
    },
    cancelOffer(offerId: number): Promise<boolean> {
      return CourseService.cancelOffer(offerId).then(this.getCourseOccurrence);
    },
    /**
     * Triggered from editbuttons group component
     */
    cancelCoordinatorUpdate() {
      this.editEnable = false;
      this.occurrence.coordinatorId = this.currentCoordinator.id;
      this.occurrence.coordinatorName = this.currentCoordinator.name;
    },
    /**
     * Checks if the specified value is unavailable and if so,
     * replaces it with the the unavailable translation for the current locale
     * Also checks if the value is a date and if so, formats it to YYYY-MM-DD
     */
    replaceUnvailableOrFormatDate(value: string): string {
      return CourseService.replaceUnvailableOrFormatDate(
        value,
        this.$t("course-occurrence.unavailable")
      );
    },
  },
  mounted() {
    this.getCourseOccurrence();
  },
  watch: {
    $route() {
      this.getCourseOccurrence();
    },
  },
})
export default class CourseOccurrenceDetails extends Vue {}
